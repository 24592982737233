@layer components {
  .container {
    display: flex;
    gap: var(--space-1x);
    align-items: center;
    justify-content: flex-start;
    font-size: var(--rating-size);
  }

  .ratingText {
    display: var(--rating-text-display);
    line-height: 1;
    color: var(--rating-text-color);
    font-size: var(--rating-size);
  }
}
