/*
@contract appBanner {
  closeButton {
    color: Color;
  }
  position: Position
}
*/

/* stylelint-disable declaration-property-value-disallowed-list */
.appBannerWrapper {
  height: 100px;
  width: 100%;
  position: var(--app-banner-position);
  top: 0;
}

.menuOpened {
  z-index: var(--z-index-3x);
}

.menuClosed {
  z-index: var(--z-index-6x);
}

.appBanner {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  padding: 0 var(--space-3x) 0 0;
  font-size: var(--font-size-body);
  background-color: var(--color-white);
  box-shadow: inset 0 -1px 0 var(--color-gray-200);
  z-index: var(--z-index-1x);
  position: relative;
  @media (--screen-md) {
    padding: 0 var(--space-6x) 0 0;
  }
}

.closeButton {
  --button-padding: 0 var(--space-2-5x);
  --button-font-size: var(--font-size-xl);
  --button-text-color-base: var(--close-app-banner-button-color);
}

.header {
  color: var(--color-black);
}

.logo {
  min-width: 55px;
  min-height: 55px;
  border-radius: 25%;
  margin-right: 10px;
  overflow: hidden;
}

.body {
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
}

.rating {
  display: flex;
  align-items: center;
}

.ratingCount {
  margin-left: 5px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
}

.ctaWrapper {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;

  --button-height: 38px;
  --button-padding: 6px 12px;
  --button-font-size: var(--font-size-sm);
}

.ctaButton {
  font-size: 12px;
  padding: 6px 12px;
}
