@layer components {
  .starBackDrop {
    display: block;
    font-family: serif; /* provide a sharp-pointed star glyph */
    position: relative;
    font-size: var(--rating-star-size);
    color: var(--rating-star-backdrop-color);
    letter-spacing: var(--rating-star-letter-spacing);

    &::after {
      content: var(--rating-star-backdrop-content);
    }
  }

  .stars {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    color: var(--rating-star-color);

    &::after {
      content: var(--rating-star-content);
    }
  }
}
