.helpfulText {
  margin-right: var(--space-3x);
}

.count {
  margin-left: var(--space-1x);
}

.helpfulContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.helpfulAnchor {
  cursor: pointer;
}

.reviewIcon:hover,
.activeIcon {
  color: var(--color-indigo-500);
  cursor: pointer;
}
