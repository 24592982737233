.reviewCard {
  height: auto;
  padding-top: var(--space-5x);
  padding-bottom: var(--space-5x);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: unset;
  border-top: 1px solid var(--color-gray-200);
  color: var(--color-gray-500);
  font-size: var(--font-size-body);

  @media (--screen-lg) {
    min-height: 322px;
    padding: var(--space-4x);
    border: 1px solid var(--color-gray-200);
    border-radius: var(--border-radius-2x);
    font-size: var(--font-size-body);
  }
}

.reviewCardSkeleton {
  justify-content: space-between;
}

.reviewCardText {
  margin-bottom: var(--space-1x);
  height: auto;
  overflow: hidden;
  font-style: var(--font-size-body);
  max-height: 85px;
}

.reviewCardHeader {
  display: flex;
  flex: 1;
}

.authorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: auto;
}

.reviewCardAuthor {
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
  margin-top: var(--space-8x);
}

.reviewCardHeaderContainer {
  margin-bottom: var(--space-8x);
  position: relative;
}

.sourceLogo {
  position: absolute;
  right: 0;
  top: 0;
}

.reviewCompany {
  margin-bottom: var(--space-1x);
  font-size: var(--font-size-sm);
}

.reviewDate {
  margin-top: var(--space-1x);
  font-size: var(--font-size-body);
}

.commentsLink {
  margin-top: var(--space-1x);
}

.commentsLink:hover {
  text-decoration: underline;
  color: var(--color-indigo-600);
  cursor: pointer;
}

.reviewActions {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-4x);
  color: var(--color-gray-500);
  font-weight: var(--font-weight-normal);
}

.reviewCardContent {
  display: flex;
  flex-direction: column;
  flex: 1;
}
