.modal {
  --modal-padding: var(--space-6x) var(--space-6x) var(--space-6x) 0;

  @media (--screen-md) {
    --modal-width: 748px;
  }
}

.review {
  border: unset;
  padding: var(--space-1x) var(--space-8x) var(--space-7x) var(--space-14x);
}

.reviewText {
  overflow: unset;
  max-height: unset;
  font-size: var(--font-size-xl);
}

.managerResponseContainer {
  background-color: var(--color-gray-500);
  border-radius: var(--border-radius-2x);
  color: var(--color-white);
  padding: var(--space-4x);
  margin-top: var(--space-5x);
  font-size: var(--font-size-body);
}

.commentAuthor {
  font-weight: var(--font-weight-semibold);
}

.commentText {
  margin-top: var(--space-4x);
}

.commentsLink {
  font-weight: var(--font-weight-normal);
  color: var(--color-gray-500);
  margin-top: var(--space-1x);
}

.textContainer {
  height: unset;
  overflow: auto;

  @media (--screen-md) {
    height: fit-content;
  }
}

.textClassName {
  height: unset;
  max-height: unset;
}
